<template xmlns="http://www.w3.org/1999/html">
  <div class="pay-container">
    <div class="pay-content">
      <div class="pay-header">
        <el-dialog
            width="30%"
            title="折扣"
            close-on-click-modal="false"
            close-on-press-escape="false"
            show-close="false"
            :visible.sync="innerVisible"
            @close="oncloseEvent"
            append-to-body>
          <div class="payMoney-left"><span class="discount"></span>
            <div class="payMoney-left-box">
            <el-form :model="formData" :rules="rules">
              <el-form-item
                  label="折扣"
                  prop="discount"
              >
                <el-input style="border: none" v-model="formData.discount" placeholder="折扣0.5——1">
                </el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="queding" style="margin-bottom: 22px">确 定</el-button>

  </span>
          </div>
          </div>


        </el-dialog>
        <div slot="footer" class="dialog-footer">

          <el-button v-if="isShow" type="primary" @click="innerVisible = true">打折</el-button>
        </div>

        <div class="payMoney-left"><span class="fuhao">￥</span><span class="payMoney"> {{ payMoney }}</span></div>
        <div class="payMoney-right">
          <span>找零:</span>
          <span style="font-size: 30px" class="zhaoling-money"> {{ zhaolingMoney }}</span>
        </div>
      </div>
      <div class="pay-body">
        <div class="pay-body-center">
          <div class="inputDeep">
            <el-input style="border: none" ref="input" v-model="input" placeholder="请输入内容"
                      @keyup.enter.native="handleEvent" :readonly="isReadonly" autofocus clearable @blur="blurEvent">
              <template #prefix>
                <i class="el-icon-full-screen"></i>
              </template>
            </el-input>
          </div>
        </div>
        <div class="pay-body-box">
          <div class="pay-body-left">
            <div class="pay-body-left-header-box" style="border-radius: 10px">
              <el-image src="https://pic.rmb.bdstatic.com/bjh/down/a36cf5452e2d81e173add6b343ef2e5b.png"
                        fit="contain" class="img-left"></el-image>
              <el-image
                  src="https://q3.itc.cn/q_70/images03/20240119/e6d0d3a05553429097179be1a6ad1aa5.jpeg"
                  fit="contain" class="img-right"></el-image>
            </div>
            <div class="pay-body-left-conter-box">
              <div class="money-box">
                <b style="margin-left: 30%">已收：</b>
                <span class="pay-body-left-conter-box-money">{{ Number(displayValue).toFixed(2) }}</span>
              </div>
            </div>
            <!--                        <div class="pay-body-left-footer-box">-->
            <!--                            <el-image src="https://pic.rmb.bdstatic.com/bjh/down/a36cf5452e2d81e173add6b343ef2e5b.png"-->
            <!--                                fit="fit" class="img-left "></el-image>-->
            <!--                            <el-image-->
            <!--                                src="https://q3.itc.cn/q_70/images03/20240119/e6d0d3a05553429097179be1a6ad1aa5.jpeg"-->
            <!--                                fit="fit" class="img-conter"></el-image>-->
            <!--                            <el-image-->
            <!--                                src="https://taijiyunwaitui.com/wp-content/uploads/2022/04/25eb9e4bdf2944248251071846c0ae99.jpg"-->
            <!--                                fit="fit" class="img-riht"></el-image>-->
            <!--                        </div>-->
            <div class="dialog-footer2">
              <el-button @click="closeClick">取 消</el-button>
            </div>
          </div>
          <div class="pay-body-right">
            <div class="calculator" style="border-radius: 10px">
              <div class="buttons">
                <button v-for="(number, index) in numbers" :key="index" class="btns"
                        :class="ifClick && indexs == index? 'btnsColor':''"
                        @click="handleButtonClick(number, index)"
                >
                  {{ number }}
                </button>
              </div>
              <div class="btnText_box">
                <button class="btns btnsText" v-if="index2==0 || index2==1" :style="index2== 2? 'height:130px':''"
                        v-for="(numberText, index2) in numbersText"
                        :key="index2"
                        :class="ifClick2 && indexs2 == index2? 'btnsColor':'btnsText2'"
                        @click="handleButtonClick(numberText.id, index2)"
                >{{ numberText.name }}
                </button>
                <button class="btns btnsText" v-if="index2 == 2" :style="index2== 2? 'height:130px':''"
                        v-for="(numberText, index2) in numbersText"
                        :key="index2"
                        :class="ifClick2 && indexs2 == index2? 'btnsColor':'btnsText2'"
                        @click="handleButtonClick(numberText.id, index2)"
                >现金<br>结算
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  props: {
    goodsData: {}
  },
  data() {
    var discountData = (rule, value, callback) => {
      console.log("rule", rule)
      let that = this
      this.isReadonly = true
      this.lock = false
      if (!value) {
        return callback(new Error('折扣不能为空'));
      }
      setTimeout(() => {
        console.log(value)
        if (!(value <= 1 && value >= 0.5)) {
          callback(new Error('折扣必须0.5-1之间'));

        } else {
          if (value.length > 4) {
            callback(new Error('输入折扣过长'));
          } else {
            that.lock = true

            that.payMoney = Math.round((that.payOldMoney * value) * 100) / 100
            that.$refs.input.focus();
            setTimeout(() => {
              that.isReadonly = false
            }, 400)
            callback()

          }

        }
      }, 100);
    };
    return {
      isShow: false,
      innerVisible: false,
      isReadonly: true,
      lock: true,
      formData: {
        discount: 1
      },
      rules: {
        discount: [
          {
            validator: discountData, trigger: 'blur'
          }
        ]
      },
      payOldMoney: '0.00',//支付金额
      payMoney: '0.00',//支付金额
      input: '',
      displayValue: '0',//显示金额
      numbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', '00'],
      numbersText: [
        {
          name: '退格',
          id: 'tg'
        }, {
          name: '清空',
          id: 'qk'
        }, {
          name: '现金<br>结算',
          id: 'xj'
        }
      ],
      // numbersText:['退格','清空','现金'],
      // numbers: ['1', '2', '3', '退格', '4', '5', '6', '清空', '7', '8', '9', '现金', '.', '0', '00'],
      screenWidth: 0,
      screenHeight: 0,
      ifClick: false,
      indexs: -1,
      ifClick2: false,
      indexs2: -1,
    }
  }, computed: {
    zhaolingMoney() {
      //只返回两个小数点

      return this.displayValue - this.payMoney <= 0 ? 0.00 : (this.displayValue - this.payMoney).toFixed(2)
    },
    ...mapState({
      roleList: (state) => state.roleList
    }),
  },

  mounted() {
    console.log("1234567890")
    let that = this
    this.getGoodsData();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // 确保输入框在组件挂载后自动获得焦点
    this.$nextTick(() => {
      this.$refs.input.focus();
      setTimeout(() => {
        that.isReadonly = false
      }, 400)
    });

  },
  created() {
    console.log(" this.roleList", this.roleList)
    this.isShow = this.roleList.includes("shop-manager")

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  },

  methods: {

    oncloseEvent() {
      console.log("关闭")
      this.$refs.input.focus()
    },
    blurEvent() {
      console.log("失去焦点")
      if (this.lock && !this.innerVisible) {
        console.log("获取焦点")
        this.$refs.input.focus()
      }
    },
    queding() {
      this.innerVisible = false
    },
    updateWindowDimensions() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight + 20;
    },
    closeClick() {
      this.$emit('cancel', '')
    },
    getGoodsData() {
      this.payMoney = this.goodsData().totalPrice
      this.payOldMoney = this.payMoney
    },
    handleButtonClick(number, index) {
      this.ifClick = false;
      this.indexs2 = index;
      this.ifClick2 = true;
      console.log('number:', number, 'index:', index)
      if (number == 'tg') {
        this.handleBackspace()
      } else if (number == 'qk') {
        this.handleClear()
      } else if (number == 'xj') {
        this.handleCash()
      } else {
        this.ifClick = true;
        this.ifClick2 = false;
        this.indexs = index;
        if (this.displayValue === '0') {
          this.displayValue = number;
        } else {
          this.displayValue += number;
        }
      }

      // if (index == 3 || index == 7 || index == 11) {
      //     if (number == '清空') {
      //        this.handleClear()
      //     } else if (number == "退格") {
      //       this.handleBackspace()
      //     } else {
      //        this.handleCash()
      //     }
      // } else {
      //     if (this.displayValue === '0') {
      //         this.displayValue = number;
      //     } else {
      //         this.displayValue += number;
      //     }
      // }
    },
    handleClear() {
      this.displayValue = '0';
    },
    handleBackspace() {
      // TODO实现退格功能
      this.displayValue = this.displayValue.slice(0, -1);
      if (this.displayValue === '') {
        this.displayValue = '0';
      }
    },
    handleCash() {
      if (!this.lock) {
        this.$message.error('折扣率有错误，请重新输入');
        return;
      }
      // 如果已收现金低于商品价格，不能够提交
      var AmountReceived = parseFloat(this.displayValue);
      var Payprice = parseFloat(this.payMoney);
      if (AmountReceived < Payprice) {
        this.$message.error('已收现金低于商品价格，不能够提交');
        return;
      }
      let data = {
        payType: 'cash',//支付方式
        payMoney: this.displayValue, // 支付金额（已收金额）
        zhaolingMoney: this.zhaolingMoney,
        discount: this.formData.discount,
        authCode: ""
      }
      this.$emit('submit', data)
    },
    payMonth(e) {
      console.log("=============", e)
    },
    isPaymentCode(s) {
      // 支付宝付款码：25位数字
      const alipayRegex = /^\d{25}$/;
      // 微信支付付款码：18位数字
      const wechatRegex = /^\d{18}$/;
      const yunRegex = /^\d{19}$/;
      console.log(s)
      return alipayRegex.test(s) || wechatRegex.test(s) || yunRegex.test(s);
    },
    handleEvent() {

      if (!this.lock) {
        this.$message.error('折扣率有错误，请重新输入');
        return;
      }
      console.log("------------------", this.input)
      this.input = this.input.trim()
      let s1 = this.input
      if (!this.isPaymentCode(s1)) {
        this.$message.error('付款码错误，请重新输入');
        return;
      }


      let data = {
        payType: "swept",//支付方式
        payMoney: this.displayValue, // 支付金额（已收金额）
        zhaolingMoney: this.zhaolingMoney,
        discount: this.formData.discount,
        authCode: this.input
      }
      this.$emit("submit", data)
      this.input = ''

    },


    scanCode() {
      //TODO
      // 扫码功能的处理逻辑
      // 这里应该调用实际的扫码功能
    }
  },


}
</script>

<style scoped>
.pay-container {
  height: 100%;
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
}

.payMoney {
  font-size: 30px;
  color: red;
  font-weight: bold;
  margin-left: -4px;
}

.fuhao {
  font-size: 15px;
  color: red;
}

.pay-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.payMoney-left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payMoney-right {
  padding: 5px 20px;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #94C4DA;
  border-radius: 5px;
  color: #000;
}

.zhaoling-money {
  font-weight: bold;
  color: #0052A9;
}
.payMoney-left-box{
  display: flex;
  align-items: end;

}

.pay-body {
  margin-top: 20px;

}

/* 添加这部分样式来调整图标大小 */
.el-input .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-input .el-input__prefix .el-icon-full-screen {
  font-size: 30px;
  margin-top: 5px;
  margin-right: 8px;
}

.el-input .el-input__inner {
  padding-left: 32px;
}

.pay-body-left {
  padding: 5px;
  margin-top: 10px;
  width: 45%;
  height: 45vh;
  position: relative;
}

.pay-body-right {
  margin-top: 25px;
  width: 53%;
  height: 42vh;
}

.pay-body-box {
  display: flex;
  justify-content: space-between;
}

.calculator {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  border: 1px solid #e1e1e1;
  /* justify-content: space-between; */
  border-radius: 10rpx;
  padding: 10px;
  justify-content: center;
  /* align-items: center; */
}

.buttons {
  /* margin-top: 20px; */
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
}

.btnText_box {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
}

.btns {
  width: 60px;
  height: 60px;
  background-color: #ffffff;
}

.btnsColor {
  background-color: #0681f3;
  color: #ffffff;
}

.btnsText {
  margin-bottom: 10px;
}

.btnsText2 {
  background: #99C2DC;
}

.btnsText:nth-child(3) {
  background: #11AFD9;
}

.buttons2 button:nth-child(3n+3) {
  background-color: rgb(76, 86, 76);
  color: #fff;
}

.pay-body-left-header-box {
  margin-top: 10px;
  padding: 25px 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  border-radius: 10rpx;
}

.el-image {
  margin-left: 10px;
  border-radius: 10px;
}

.pay-body-left-conter-box {
  border-radius: 10px;
  background-color: #fff;
  margin-top: 8vh;
}

.money-box {
  padding: 10px 20px 20px 10px;
  /* margin-top: 10px; */
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.pay-body-left-footer-box {
  padding: 10px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.zhaoling {
  display: flex;
  justify-content: space-between;
  background-color: grey;
}

.inputDeep .el-input--medium .el-input__inner {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
}

.dialog-footer2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.discount {
  font-size: 20px;
  color: rgba(16, 15, 15, 0.99);
  font-weight: bold;
  margin-left: -4px;
}
</style>
