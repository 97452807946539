<template>
  <div>
  <div class="category-sidebar">

<!--    <el-breadcrumb separator-class="el-icon-arrow-right"  separator="/">-->
<!--      <el-breadcrumb-item v-for="category in categories" :key="category.id" @click="handoffType(category)">-->
<!--        {{ category.name }}</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
    <el-tabs v-model="name" @tab-click="handoffType">
      <el-tab-pane v-for="category in categories" :key="category.id" :label="category.name" :name="category.name">{{category.name}}</el-tab-pane>
    </el-tabs>
  </div>
    <div class="product-list">
      <div v-for="data in categorieChild" :key="data.id" class="product-item">
        <div>

        <el-image v-if="data.thumb" class="product-image"  :src="staticUrl(data.thumb)" fit="cover"></el-image>
        </div>
        <div  class="product-details">
          <h3  class="product-name">{{ data.name }} <div style="color:red;padding-left: 6px;">¥{{data.salePrice}}</div></h3>
          <el-button  v-if="data.isSpecification==null" class="buy-button" icon="el-icon-shopping-cart-2" type="primary" @click="selectCategory(data)">添加</el-button>
          <el-button v-else class="buy-button" icon="el-icon-circle-plus-outline" type="primary"  @click="querySpecification(data)"
          >选规格
          </el-button
          >

        </div>
        </div>

    </div>

    <el-dialog title="选规格" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false"	@close="beforeClose" >


      <div class="coninfo">
      <div v-for="option in data" :key="option.id" class="itemArr">
        <div class="" style="font-size: 16px;margin-left: 4px; padding-bottom: 2px;"> {{option.optionName}}</div>
        <div v-if="option.optionType=='1'">
<!--          <div v-for="entry in option.lwSpecificationEntryList" :key="entry.id">-->
          <el-radio-group v-model="option.values">

            <el-radio-button v-for="item in option.lwSpecificationEntryList" :label="item.detailValues" >
              {{ item.detailValues }} {{ (item.priceAdjustment!=null&&item.priceAdjustment!=0)?'+'+item.priceAdjustment+"元":'' }} </el-radio-button>
          </el-radio-group>
<!--          </div>-->
        </div>
        <div v-else>
          <el-checkbox-group v-model="option.values">
            <el-checkbox-button v-for="item in option.lwSpecificationEntryList" :label="item.id" >{{item.detailValues}}</el-checkbox-button>
          </el-checkbox-group>
        </div>

      </div>

      </div>



      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormConfirm">确 定</el-button>
      </div>
    </el-dialog>




  </div>
</template>

<script>
import {configService } from "@/common/service/config.service"
import {mapMutations, mapState} from "vuex";
export default {
  data() {
    return {
      dialogFormVisible: false,
      categories: [

      ],
      categorie:{},
      categorieChild:[],
      name:'',
      data:'',
      specificationList:[]
    };
  },
  computed:{
    ...mapState({
      shopInfo: (state) => state.shopInfo,
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    this.findData()
  },
  methods: {
    dialogFormConfirm(){
      console.log("=====",this.data)
   let categorie =   JSON.parse(JSON.stringify(this.categorie))
      let data = JSON.parse(JSON.stringify(this.data))
      let json =new Map()
      let dataList = []
      let price =  0
      for (let i = 0; i < data.length; i++) {
        if(data[i].optionType=='1'){
          if(data[i].values==''){
            this.$message.error("请选择规格")
            return
          }else{
       let  entryList =   data[i].lwSpecificationEntryList
            for (let j = 0; j < entryList.length; j++) {
              json.set(entryList[j].id,entryList[j].detailValues)
              if(entryList[j].detailValues==data[i].values){
               data[i].id=entryList[j].id
                dataList.push(entryList[j].id)
                if(entryList[j].priceAdjustment!=null&&entryList[j].priceAdjustment!=''){
                  price += entryList[j].priceAdjustment
                }


              }else{

              }
            }
          }
          } else{
          if(data[i].values==[]){

          }else{
            let  entryList =   data[i].lwSpecificationEntryList
            for (let j = 0; j < entryList.length; j++) {
              if(data[i].values.indexOf(entryList[j].id)>-1){
                dataList.push(entryList[j].id)
                if(entryList[j].priceAdjustment!=null&&entryList[j].priceAdjustment!=''){
                  price += entryList[j].priceAdjustment
                }
              }else{
                continue
              }
            }
          }

        }

      }
      dataList.sort();
      console.log(dataList)
      console.log(price)
      let specificationName =''
      for (let i = 0; i < dataList.length; i++) {
        if(i==0){
          specificationName += json.get(dataList[i])
        }else{
          specificationName += "/"+json.get(dataList[i])
        }

      }

      categorie.specification = dataList.join(",")
      categorie.specificationName  = "("+specificationName+")"

      if(price!=0){
        categorie.salePrice += price
        categorie.salePrice = categorie.salePrice.toFixed(2)
      }

      console.log("===========",categorie)
      this.$emit('selectCategory', categorie);
      this.dialogFormVisible=false
    },
    selectedOptions(){

    },
    beforeClose(){
      this.categorie =''
      this.data = ""
console.log("关闭")
    },

    staticUrl(url){
      // console.log(url)
      // console.log(process.env.VUE_APP_IMG_URL + url)
      return process.env.VUE_APP_IMG_URL + url
    },
    handoffType(data){
      console.log(data._props.name)
      let newname = data._props.name
      // if(this.name==newname){
      //   return
      // }
      for (let i = 0; i < this.categories.length; i++) {
        console.log(i)
       if(this.categories[i].name ==newname){
         console.log(this.categories[i])
         this.categorieChild=this.categories[i].list
       }
      }
      // this.categorieChild=data.list
    },
    querySpecification(data){

      console.log(data)
      this.$loading()
      this.categorie=data

      this.$http.get("/specification/lwSpecificationRelation/specificationByid?materialId="+data.id).then(res=>{
        if(res.code ==200){
          this.dialogFormVisible =true
          console.log(res.result)
       let   data=res.result
          for (let i = 0; i < data.length; i++) {
            if(data[i].optionType=='1'){
              data[i].values=data[i].defaultsption_dictText

            }else{
              data[i].values=[]
             data[i].values.push(data[i].defaultsption_dictText)
            }
          }
          this.data=data
        }else{

        }
        this.$loading().close()
      })
    },
    findData(){
      this.$http
          .get(`/base/basMaterial/queryByMaterialIdByType`).then(res=>{
            console.log("========",res)
        if(res.code==200){
          this.categories=res.result
          if (this.categories.length>0){
            this.name =  this.categories[0].name
            this.categorieChild = this.categories[0].list
          }

        }

      })
    },
    selectCategory(category) {
      this.$emit('selectCategory', category);
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.option-group {
  margin-bottom: 10px;
}

.option-group label {
  display: block;
  margin-bottom: 5px;
}

.radio-option {
  margin-left: 15px;
}

.radio-option input[type="radio"] {
  margin-right: 5px;
}







.category-sidebar {
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  //justify-content: center;
  padding: 16px;
  overflow: auto;
  height: calc(100vh - 180px);
}

.product-item {
  width: 150px;
  height: 270px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 150px;
  height: 170px;
  object-fit: cover;
}

.product-details {
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

.product-name {
  margin: 0;
  font-size: 16px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.buy-button {
  //background-color: #a43931;
  width: 100%;
  margin-top: 10px;
}
.coninfo{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.itemArr{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
</style>
